.-footer{
	position: relative;
	background-color: darken($blue-base, 14%);
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
	color: $white;

	&-inner{
		position: relative;
		display: flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			-webkit-flex-direction: column;
		}
	}

	&-brand{
		flex: 0 0 auto;
		width: auto;
		@include media-breakpoint-down(md) {
			margin-bottom: 15px;
		}

		.brand{
			font-size: 24px;
			@include font-rem(24);
			font-weight: $bold;
			position: relative;
			color: darken($white, 20%);
			@extend %flex-dir-col-wrap;
			letter-spacing: 2.3px;
			text-transform: uppercase;
			font-weight: $bold;
			line-height: 1;
			strong{
				color: darken($white, 50%);
				font-weight: $semibold;
				letter-spacing: -1px;
				font-size: 15px;
				@include font-rem(15);
			}
		}	
		a{
			color: inherit;
			text-decoration: none;
			@include transition();
			&:active,
			&:focus,
			&:hover{
				transform: scale(1.03);
				opacity: .9;
			}
		}
	}	

	&-copy{
		flex: 1;
		margin-bottom: 0;
		font-size: 14px;
		@include font-rem(14);
		line-height: 1.8;
		text-align: center;
		padding-right: 10px;
		padding-left: 10px;
		@include media-breakpoint-down(md) {
			order: 3;
		}
		a{ 
			color: $green-lime;
			text-decoration: none;
		}
		p{
			margin-bottom: 0;
			/*@include media-breakpoint-only(md) {
				margin-bottom: 20px;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}*/
		}		
	}

	&-socials{
		flex: 0 0 auto;
		width: auto;
		padding: 0;
		margin: 0;
		@extend %default-list-clean-style;
		@extend %flex-dir-row-wrap;
		font-size: 20px;
		@include font-rem(20);

		@include media-breakpoint-down(md) {
			flex: 1;
		}
		
		li{
			margin-bottom: 10px;
			margin-right: 10px;

			&:last-child{margin-right: 0;}
		}

		a{
			@include transition();
			width: 40px;
			height: 40px;
			color: inherit;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				color: inherit;
			}
		}
	}

}