.-skills{
	&-section{
		@extend %shadow-add;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	&-row{
		align-items: center;
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
		}
	}
	&-list{
		margin: 0;
		padding: 0;
		@extend %flex-dir-row-wrap;
		li{
			flex: 0 0 auto;
			width: calc(25% - 10px);
			background-color: var(--c-purple-base);
			color: $white;
			font-weight: $medium;
			padding: 8px 5px;
			@include border-radius(8px);
			margin: 5px;
			@extend %flex-dir-col-wrap;
			justify-content: center;
			align-content: center;
			text-align: center;
			font-size: 3em;
			small{ font-size: .7rem;}
			@include media-breakpoint-down(md) {
				width: calc(50% - 10px);
			}
		}
	}
	&-code-eg{
		@include border-radius(10px);
		@extend %default-box-shadow;
		margin-bottom: 20px;
		@include media-breakpoint-down(md) {
			margin-top: 30px;
			margin-bottom: 0;
		}
	}
}