.-testimonials{
	@extend %flex-dir-row-wrap;
	@extend %flex-samesize;
	justify-content: space-between;
	@include media-breakpoint-down(md) {
		/*flex-direction: row;*/
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow-x: auto;
		padding-bottom: 30px;
	}
	&-section{
		/*@extend %gradient-y-three-testimonials;*/
		background-color: $blue-base;
		color: $white;
		position: relative;
		.container,
		.container-custom{
			position: relative;
		}
		.container-custom{
			@include media-breakpoint-down(md) {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	margin-top: 20px;
	&-svg{
		position: absolute;
		top: 5rem;
		left: 50%;
		.gray{
			&-50{color: rgba($gray-base, .5);}
			&-200{color: rgba($gray-base, .2);}
		}
		.white{
			&-20{color: rgba($white, .2);}
			&-50{color: rgba($white, .5);}

			&-100{color: rgba($white, 1);}
		}
		.blue{
			&-50{color: $blue-base;}
		}
		-webkit-transform: translateX(16rem);
		   -moz-transform: translateX(16rem);
		    -ms-transform: translateX(16rem);
		     -o-transform: translateX(16rem);
		        transform: translateX(16rem);
		@include media-breakpoint-down(md) {
			-webkit-transform: translateX(0);
			   -moz-transform: translateX(0);
			    -ms-transform: translateX(0);
			     -o-transform: translateX(0);
			        transform: translateX(0);
			        opacity: .4;

		}
		
	}	
	&-item{
		/*@extend %flex-dir-row-wrap;*/
		@extend %flex-dir-col-wrap;
		flex: 0 0 auto;
		width: calc(33.3333% - 20px);
		justify-content: center;
		align-items: center;

		@include media-breakpoint-down(md) {
			width: 80vw;
			margin-right: 10px;
			margin-left: 10px;
		}
		/*margin-bottom: 20px;*/
		/*&:nth-child(even){
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
				.-testimonials-content{
					-webkit-transform: translate3d(20%, 0, 0);
					   -moz-transform: translate3d(20%, 0, 0);
					    -ms-transform: translate3d(20%, 0, 0);
					     -o-transform: translate3d(20%, 0, 0);
					        transform: translate3d(20%, 0, 0);
				}
			}
		}*/
		&:last-child{
			margin-bottom: 0;
		}
		p{
		
		}

	}
	&-photo{
		/*@include make-col(6);*/
		margin-bottom: 10px;
		@include media-breakpoint-down(md) {
			width: 100%;
			margin-bottom: -20px;
		}
		img{
			@include border-radius(10px);
		}
	}
	&-content{
		@extend %default-box-shadow;
		@include border-radius(6px);
		background-color: darken($blue-base, 10%);
		color: $white;
		text-align: left;
		/*background-color: var(--c-bg-lighten-20);*/
		/*color: var(--c-white-primary);*/
		flex: 1;
		padding: 15px;
		@extend %flex-dir-col-wrap;
		@include media-breakpoint-up(md) {
			/*-webkit-transform: translate3d(-20%, 0, 0);
			   -moz-transform: translate3d(-20%, 0, 0);
			    -ms-transform: translate3d(-20%, 0, 0);
			     -o-transform: translate3d(-20%, 0, 0);
			        transform: translate3d(-20%, 0, 0);*/
		}
		@include media-breakpoint-down(md) {
			max-width: 95%;
		}

		p{
			margin-bottom: auto;
		}
		
	}
	&-author{
		font-size: 1.5rem;
		line-height: 1;
		margin-bottom: .2em;
		text-align: right;
		margin-top: auto;
		@include media-breakpoint-down(md) {
			margin-top: 20px;
		}
	}
	&-company{
		text-align: right;
		font-size: 1.2rem;
		font-weight: $medium;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.iconify{ 
			margin-right: 5px;
			font-size: 1.2em;
		}
	}
}