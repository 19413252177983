.-contact{
	&-section{
		/*background-color: var(--c-bg-gradient-end);*/
		background-color: $blue-base;
		padding-bottom: 12px;
		position: relative;
		@include media-breakpoint-down(md) {
			padding-top: 10px;
		}
		&:before{
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 60px;
			/*background-color: $black;*/
			background-color: darken($blue-base, 14%);
		}
		.container{position: relative;}
	}
	&-wrap{
		@include media-breakpoint-up(md) {
			max-width: 80%;
		}
		margin-right: auto;
		margin-left: auto;
	}
}
