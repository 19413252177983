.-section{
	padding-top: 60px;
	padding-bottom: 60px;
	@include media-breakpoint-down(md) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	&-alt{
		/*padding-top: 30px;
		padding-bottom: 40px;*/
		/*background-color: var(--c-bg-lighten-20);*/
		background-color: $blue-base;
		color: $white;
	}

	&-wrap{
		@include make-row();
		justify-content: space-between;
		align-items: center;
		@include media-breakpoint-down(md) {
			padding-right: 20px;
			padding-left: 20px;
		}
		&.-align-start{ align-items: flex-start;}
		&.-justify-center{ justify-content: center;}
	}

	&-about{
		font-size: 14px;
		@include font-rem(14);
		&-owner{
			@extend %flex-dir-row-wrap;
			figure{
				margin-bottom: 0;
				max-width: 40%;
				img{
					border-radius: 6px;
				}
				@include media-breakpoint-down(md) {
					max-width: 90%;
					margin-left: 5%;
					margin-bottom: 30px;
				}
			}
			&-infos{
				flex: 1;
				padding-left: 20px;
			}
			ul{ margin-bottom: 1rem;}
		}
		p{
			margin-bottom: .5rem;
		}
	}
	&-press{
		position: relative;
		color: $blue-base;
		.container,
		.container-custom{
			position: relative;
			overflow: hidden;
			@include media-breakpoint-down(md) {
				padding-bottom: 20px;
			}

		}
		.container-custom{
			@include media-breakpoint-down(md) {
				padding-right: 0;
				padding-left: 0;
			}
		}
		
		.-title-primary{
			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
		}
		&-svg{
			position: absolute;
			top: 5rem;
			right: 100%;
			.gray{
				&-50{color: rgba($gray-base, .5);}
				&-200{color: rgba($gray-base, .2);}
			}
			.white{
				&-50{color: rgba($white, .5);}
				&-200{color: rgba($white, 1);}
			}
			-webkit-transform: translateX(66.666667%);
			   -moz-transform: translateX(66.666667%);
			    -ms-transform: translateX(66.666667%);
			     -o-transform: translateX(66.666667%);
			        transform: translateX(66.666667%);

			@include media-breakpoint-down(md) {
				top: 4rem;	
				-webkit-transform: translateX(16%);
				   -moz-transform: translateX(16%);
				    -ms-transform: translateX(16%);
				     -o-transform: translateX(16%);
				        transform: translateX(16%);				
			}
			
		}
	}

	&-products{
		color: $blue-base;
		&-intro{
			border-bottom: 1px solid;
			border-color: lighten($gray-base, 50%);
			margin-bottom: 40px;
			padding-bottom: 20px;
		}
	}

}