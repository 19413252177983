body {
  /*background-color: darken($green-base, 80%);*/
  /*background-color: lighten($gray-base, 60%);*/
  background-color: var(--c-bg-body-primary);
  color: var(--c-text-primary);
  font-family: $base-font-family;
  font-size: 16px;
  @include font-rem(16);
  /*color: $gray-base;*/
  font-weight: $regular;
  position: relative;

  /*&::-webkit-scrollbar {
      width: 10px;
  }
   
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
      border-radius: 0;
      background-color: darken($white-soft, 10%);
  }
   
  &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
      background-color: var(--c-purple-base);
  }*/
  
}

main{ 
  overflow-y: hidden;
  overflow-x: hidden;
}
.hide{display: none;}
.fixed{ position: fixed; }
.absolute{ position: absolute; }

.-overflow{
  overflow-x: hidden;
}

.container-custom{
  /*@include make-container(120px);*/
  @include make-container(240px);

  @include media-breakpoint-down(xxl){
    @include make-container(120px);
  }
  @include media-breakpoint-down(lg){
    @include make-container(60px);
  }
  @include media-breakpoint-down(md){
    @include make-container(30px);
  }
}

.h-100{ height: 100%;}

ul{
  @extend %default-list-clean-style;
  margin: 0;
  &.stylish{
    padding-left: 1em;
    li{
      line-height: 1.6;
      &::before{
        margin-right: 10px;
        content: "\2022";
        /*color: var(--c-purple-base);*/
        color: $white;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  &.checklist{
    padding-left: 0;
    li{
      /*line-height: 1.6;*/
      &+li{margin-top: .3em;}
      &::before{
        content: url('https://api.iconify.design/fe/check.svg?color=%2390C902&width=22');
        /*line-height: 22px;*/
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
        margin-right: 3px;
      }

      &.disabled{
        opacity: .4;
        &::before{
          content: url('https://api.iconify.design/foundation/x.svg?color=%23aaaaaa&width=16');
          line-height: 16px;

        }
      }
    }
  }
}

.-i2{
  position: relative;
  z-index: 2;
}

.txtcenter{ text-align: center; }
.txtright{ text-align: center; }

.txtcenter-md{ 
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.img-responsive{
	@extend %image-responsive;
}

.horizontal-list-clean-style {
   @extend %default-list-clean-style;
   @extend %flex-dir-row;
}

.-flex-row{
	@extend %flex-dir-row;
	align-items: center;

}
.-align-start{ align-items: flex-start;}
.-hfull{
  min-height: 100vh;
}

.-space-between{
	justify-content: space-between;
}

blockquote{
  max-width: 70%;
  font:{
    size: 18px;
    weight: $bold;
  };
  @include font-rem(18);
  line-height: 1.5;   
  color: $gray-dark;
  display: flex;
  justify-content: center;
  margin: 40px auto 13px;
  text-align: left;


  
  p{
    border-left: 4px solid;
    border-color: var(--c-purple-base);
    padding-left: 47px;
    line-height: inherit;
    margin-bottom: 0;
  }

  &+p,
  &+h3,
  &+h4,
  &+h5,
  &+h6{ margin-top: 56px; }
}

.grayscale{
  @include grayscale();
  &:hover{
    @include grayscale-on-mouseover();
  }
}

.py{
  &-6{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}