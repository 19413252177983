.-box{
	@include transition(all .2s ease-in);
	@extend %default-box-shadow;
	/*width: 100%;*/
	/*margin-bottom: 60px;*/
	margin-bottom: 20px;
	@include border-radius(6px);
	/*text-align: center;*/
	@include media-breakpoint-down(sm) {
		margin-right: 0;
		/*margin-bottom: 25px;*/
		/*@include border-radius(26px);*/
	}
	&:hover,
	&:focus{
		/*-webkit-transform: translate3d(0,-5px, 0);
		   -moz-transform: translate3d(0,-5px, 0);
		    -ms-transform: translate3d(0,-5px, 0);
		     -o-transform: translate3d(0,-5px, 0);
		        transform: translate3d(0,-5px, 0);*/
	}

	
	a{
		@include transition(all .2s ease-in);
		padding: 15px;
		height: 100%;
		/*width: 100%;*/
		text-decoration: none;
		color: inherit;
		@extend %flex-dir-row-wrap;
		/*justify-content: center;*/
		justify-content: flex-start;
		color: $blue-base;
		background-color: darken($white, 2%);
		@include border-radius(6px);
		border: 1px solid transparent;
		&:hover,
		&:focus{
			box-shadow: 5px 10px 10px rgba($green-lime, .1), 1px 1px 1px rgba($green-lime, .19), -1px -1px 1px rgba($green-lime, .19) ;
		}
		
	}

	.-mockup{
		//background: url('../images/browser-mockup-head.png');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: contain;
		margin-bottom: 0;
		/*padding-top: 19px;*/
		/*margin-bottom: -20px;*/
		@extend %default-box-shadow;
		border-radius: 8px;
		overflow: hidden;
		width: 50%;
		@include media-breakpoint-down(xxl) {
			width: 40%;
		}
		max-width: 290px;
		/*transform: translate3d(0, -36px, 0);*/
		@include media-breakpoint-down(md) {
			width: 100%;
			/*margin-bottom: -30px;*/
		}
		img{ @extend %image-responsive;}
	}	

	.-content{ 
		flex: 1;
		padding-left: 20px;
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-top: 10px;
		}
	}

	&-wrap{
		@extend %flex-dir-row-wrap;
		align-items: center;
		&-row{
			@extend %flex-dir-col-wrap;
			align-items: center;
			padding: 15px;
			text-align: center;
			/*background-color: darken($white, 7%);*/
			box-shadow: 5px 10px 10px rgba($blue-base, .01), 1px 1px 1px rgba($blue-base, .09), -1px -1px 1px rgba($blue-base, .09) ;
			height: 100%;
			@include border-radius(6px);
			.-mockup{
				width: 100%;
				/*margin-bottom: -10px;*/
				/*transform: translate3d(0, -36px, 0);*/
				background-color: transparent;
				box-shadow: none;
				border-radius: 0;
				justify-content: center;
				margin-bottom: 10px;
				img{ 
					border-radius: 8px;
					margin: 0 auto;
				}
			}
			.-content{
				padding: 0;
			}
		}
	}
	


	
	.-icon{
		/*width: 46px;
		height: 46px;
		border-radius: 46px;
		border: 3px solid $green-base;
		margin-bottom: 10px;*/
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: $medium;
		color: $green-lime;
		font-size: .9em;

		.iconify{
			font-size: 1.3em;
			color: $green-lime;
			margin-left: 5px;
		}
	}
	.-title{
		font-weight: $semibold;
		display: inline-block;
		/*display: flex;
		align-items: center;
		justify-content: flex-start;*/
		margin-bottom: 8px;
		color: $blue-base;
		/*color: darken($blue-base, 20%);*/
		.iconify{
			margin-left: 5px;
			font-size: 1.3em;
			color: $green-lime;
			display: inline;
		}
	}
	p{
		font-size: .8em;
		margin-bottom: 0;
	}

	&-grid{
		@extend %flex-dir-col-wrap;
		/*padding-top: 40px;*/
		@include media-breakpoint-down(sm) {
			flex-direction: row;
			-webkit-flex-wrap: nowrap;
			flex-wrap: nowrap;
			overflow-x: auto;
			.-box{
				min-width: 75vw;
				margin-right: 10px;
				margin-left: 10px;
			}
		}
		&-row{
			@extend %flex-dir-row-wrap;
		}
	}

	&-cols{
		&-2{
			.-box{
				flex: 0 0 auto;
				width: calc(50% - 80px);
				margin-left: 40px;
				margin-right: 40px;
				@include media-breakpoint-down(sm) {
					width: 86vw;
					margin-left: 0;
					margin-right: 15px
				}
			}
		}
		&-3{
			.-box{
				flex: 0 0 auto;
				width: calc(33.3333% - 20px);
				margin-left: 10px;
				margin-right: 10px;
				@include media-breakpoint-down(lg) {
					width: calc(50% - 20px);
				}
				@include media-breakpoint-down(sm) {
					width: 86vw;
					margin-left: 0;
					margin-right: 15px
				}
			}
		}
		&-4{
			.-box{
				flex: 0 0 auto;
				width: calc(25% - 20px);
				margin-left: 10px;
				margin-right: 10px;
				@include media-breakpoint-down(lg) {
					width: calc(50% - 20px);
				}
				@include media-breakpoint-down(sm) {
					width: calc(50% - 10px);
					margin-left: 5px;
					margin-right: 5px;
					/*width: 86vw;
					margin-left: 0;
					margin-right: 15px*/
				}
			}
		}
	}

}