.-btn{
	@include transition;
	position: relative;
	outline: none;
	display: inline-block;
	border: none;
	text-decoration: none;
	background: transparent; 
	color: inherit;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1;

	&:hover,
	&:focus,
	&:active{
		text-decoration: none;
		outline: none;
	}

	&-primary{
		@include transition();
		display: inline-block;
		/*background-color: var(--c-purple-base);*/
		background-color: $blue-base;
		color: $white;
		border: none;
		outline: none;
		font-weight: $medium;
		font-size: 16px;
		@include font-rem(16);
		border-radius: 6px;
		padding: 12px 24px;
		text-decoration: none;
		line-height: 1.5rem;
		&:hover,
		&:focus,
		&:active{
		   color: $white;
		   /*background-color: $green-lime;*/
		   background-color: darken($green-lime, 5%);
		}
	}

	&-cta{
		@include transition(all .2s ease-in);
		display: flex;
		align-items: center;
		border: 2px solid;
		/*border-color: var(--c-bg-purple-btn);
		background-color: var(--c-bg-purple-btn);*/
		background-color: $blue-base;
		border-color: $blue-base;
		color: $white;
		/*color: var(--c-purple-btn);*/
		outline: none;
		font-weight: $semibold;
		font-size: 14px;
		@include font-rem(14);
		@include border-radius(24px);
		padding: 8px;
		text-decoration: none;
		@include media-breakpoint-up(lg) {
			padding: 8px 10px;
		}
		
		.iconify{
			font-size: 18px;
			@include font-rem(18);
		}
		&:hover,
		&:focus,
		&:active{
			/*color: var(--c-bg-purple-btn);*/
			color: $blue-base;
			background-color: transparent;

		}

		span{
			&:first-child{
				margin-right: 10px;
				@include media-breakpoint-only(md) {
					display: none;
				}
			}
			/*&+.iconify{margin-left: 10px;}*/
		}
		
	}
	
	&-link{
		color: inherit;
		text-decoration: none;
	}

}

.scroll-down{
	@include transition;
	position: relative;
	outline: none;
	display: inline-block;
	border: none;
	text-decoration: none;
	background: transparent; 
	color: inherit;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1;
	outline: none;
	&:hover,
	&:focus,
	&:focus{
		outline: none;
	}
}