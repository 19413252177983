.-offcanvas{
	@extend %default-box-shadow;
	@include transition(all .25s linear);
	z-index: 2010;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	background-color: var(--c-bg-body-primary);
	/*color: inherit;*/
	color: $blue-base;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	-webkit-transform: translate3d(0, -120%, 0);
	   -moz-transform: translate3d(0, -120%, 0);
	    -ms-transform: translate3d(0, -120%, 0);
	     -o-transform: translate3d(0, -120%, 0);
	        transform: translate3d(0, -120%, 0);

	@include media-breakpoint-up(md) {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		flex-direction: row;
		-webkit-flex-direction: row;
    	padding: 0;
    	flex: 0 0 auto;
   	}	      

   	&.-visible{
		-webkit-transform: translate3d(0, 0, 0);
		   -moz-transform: translate3d(0, 0, 0);
		    -ms-transform: translate3d(0, 0, 0);
		     -o-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
   	}

   	&-row{
		@extend %flex-dir-row-wrap;
		align-items: center;
		padding: 20px 0;
   	}

	&-call{
		@include media-breakpoint-down(md) {
			margin-top: -100vh;
			@at-root {
				.menu-active &{
					margin-top: 0;
					justify-content: flex-end;
					width: 100%;

					.scroll-anchor{ 
						width: 100%;

						
						.-btn-cta{
							justify-content: center;
							padding-right: 10px;
							padding-left: 10px;	
						}
					}
				}
			}
		}

		@extend %flex-dir-row-wrap;
		a{
			display: -webkit-flex;
			display: flex;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			 
			@include media-breakpoint-down(xl) {
				margin-left: 5px;
			}
			@include media-breakpoint-down(md) {
				margin-top: 15px;
			}
			@include media-breakpoint-up(lg) {
				margin-left: 10px;
			}
			
		}
		
	}


	&-nav{	
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		height: auto;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		@extend %default-list-clean-style;
		text-transform: capitalize;

		@include media-breakpoint-up(md) {
			margin-right: auto;
        	display: -webkit-flex;
			display: flex;
			-webkit-flex-wrap: nowrap;
			flex-wrap: nowrap;
			flex-direction: row;
			-webkit-flex-direction: row;
        	align-items: center;
		}

    	
		li{
			position: relative;
			display: flex;
			height: auto;
			font-size: 16px;
			@include font-rem(16);
			
			&:before{
				@include transition(all.3s ease-in);
				content: '';
				position: absolute;
				bottom: 0;
				left: 10px;
				width: calc(100% - 20px);
				height: 2px;
				/*background-color: var(--c-text-secondary);*/
				background-color: $green-lime;
				-webkit-transform: scale(0);
				   -moz-transform: scale(0);
				    -ms-transform: scale(0);
				     -o-transform: scale(0);
				        transform: scale(0);

				        @include media-breakpoint-down(md) {
				        	left: 15px;
				        	width: calc(100% - 15px);
				        }
			}

			@include media-breakpoint-down(md) {
				display: none;

				@at-root {
					.menu-active &{
						display: block;
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
			a{
				@include transition(all, .25s, ease-in);
				display: flex;
				padding: 0 10px;
				font-weight: $medium;
				color: inherit;
				height: auto;
				width: 100%;
				justify-content: space-between;
				text-decoration: none;
				@include media-breakpoint-down(md) {
					padding: 0 15px;
				}

				&:after,
				&:before{
					@include media-breakpoint-down(md) {
						display: none;
					}
				}

				&:hover,
				&:focus{
					
				}
			}
			
			&.-active-spy{
				&:before{
					-webkit-transform: scale(1);
					   -moz-transform: scale(1);
					    -ms-transform: scale(1);
					     -o-transform: scale(1);
					        transform: scale(1);
				}
				@include media-breakpoint-down(md) {
					display: block;
				}
			}		
		}
	}

	&-active{
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		-webkit-transform: translate3d(0,0,0);
		   -moz-transform: translate3d(0,0,0);
		    -ms-transform: translate3d(0,0,0);
		     -o-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);

		        overflow-y: auto;
	}

	&::-webkit-scrollbar {
	    width: 12px;
	}
	 
	&::-webkit-scrollbar-track {
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
	    border-radius: 10px;
	    background-color: $gray-dark;
	}
	 
	&::-webkit-scrollbar-thumb {
	    border-radius: 10px;
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
	    background-color: $gray-base;
	}

	&-outter{
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		/*background-color: rgba(#000,.89);*/
		background-color: $foreground-opacity;
		display: none;
		opacity: 0;
		-webkit-transition: opacity 25s;
		-moz-transition: opacity 25s;
		-ms-transition: opacity 25s;
		 -o-transition: opacity 25s;
		    transition: opacity 25s;
			

		.menu-active &{ 
			@include media-breakpoint-down(lg) {
			    z-index: 1000;
			    display: block;
			    opacity: 1;
			}
		}

	}

	.menu-active &{ 
		-webkit-transform: translate3d(0, 0, 0);
		   -moz-transform: translate3d(0, 0, 0);
		    -ms-transform: translate3d(0, 0, 0);
		     -o-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
}