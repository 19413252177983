.-highlight{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	padding-top: 15vh;
	padding-bottom: 15vh;
	overflow: hidden;
	
	@include media-breakpoint-down(md) {
		padding-top: 60px;
		padding-bottom: 70px;
		min-height: 70vh;
	}

	/*&:before,
	&:after{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}*/

	/*&:before{
		content: '10101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010';
		color: var(--c-text-primary);
		font-weight: $wblack;
		font-size: 62px;
		line-height: 1.3;
		letter-spacing: 15px;
		word-break: break-all;
		opacity: 0.07;
	}*/
	/*&:after{
		content: '';
		@extend %gradient-x-center-text;
	}*/
	
	.container,
	.container-custom{ position: relative;}
	
	&-wrap{
		@include make-row();
		align-items: center;
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
		}
	}

	&-content{
		@include make-col(12);
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
		/*text-align: center;*/	
	}

	&-col{
		&-left,
		&-right{
			@include make-col(6);
			/*max-width: 600px;
			margin-right: auto;
			margin-left: auto;*/
			/*text-align: center;*/	
			@include media-breakpoint-down(md) {
				width: 100%;
				padding-right: 10px;
				padding-left: 10px;
			}
		}
		&-left{
			max-width: 600px;
			@include media-breakpoint-down(md) {
				margin-top: 15px;
			}
		}
		&-right{
			margin-left: auto;
			@extend %flex-wrap;
			justify-content: center;
			align-items: center;
			img{ 
				@extend %image-responsive;
				/*max-width: 300px;*/
				max-width: 36rem;
				border-radius: 0.5rem;
				@include media-breakpoint-down(xxl) {
					max-width: 34rem;
				}
				@include media-breakpoint-down(md) {
					max-width: 100%;
				}
			}
		}

	}

	&-svg{
		position: absolute;
		top: 0;
		left: 50%;
		.gray{
			&-50{color: rgba($gray-base, .5);}
			&-200{color: rgba($gray-base, .2);}
		}
		.white{
			&-50{color: rgba($white, .5);}
			&-200{color: rgba($white, 1);}
		}
		-webkit-transform: translateX(16rem);
		   -moz-transform: translateX(16rem);
		    -ms-transform: translateX(16rem);
		     -o-transform: translateX(16rem);
		        transform: translateX(16rem);

        @include media-breakpoint-down(md) {
        	opacity: .8;
			-webkit-transform: translateX(0);
			   -moz-transform: translateX(0);
			    -ms-transform: translateX(0);
			     -o-transform: translateX(0);
			        transform: translateX(0);		        	
        }
		
	}



	&-title{
		color: $blue-base;
		font-weight: $bold;
		font-size: 50px;
		@include font-rem(50);
		line-height: 1;
		margin-bottom: 0;
		letter-spacing: -1.5px;
		
		@include media-breakpoint-down(md) {
			font-size: 32px;
			@include font-rem(32);
		}

		strong{
			/*color: var(--c-text-secondary);*/
			color: $green-lime;
			font-weight: inherit;
			line-height: 1.1;
		}
	}

	&-subtitle{
		color: inherit;
		font-weight: $bold;
		font-size: 32px;
		@include font-rem(32);
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			font-size: 16px;
			@include font-rem(16);
		}
	}

	&-p{
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		line-height: 1.75rem;
		font-size: 16px;
		@include font-rem(16);

		@include media-breakpoint-down(md) {
			font-size: 14px;
			@include font-rem(14);
		}
	}

	p{
		font-weight: $regular;
		color: inherit;
		/*margin-bottom: 0;*/
	}	

	&-txt-writer{
		display: inline-block;
		margin-top: 10px;
		padding: 10px;
		background-color: var(--c-bg-lighten-5);
		color: $white;
		border-radius: 3px;
		font-weight: $bold;
		font-size: 26px;
		@include font-rem(26);
		line-height: 1.5;

		@include media-breakpoint-down(md) {
			font-size: 18px;
			@include font-rem(18);
		}
	}

	.-btn-primary{
		@include media-breakpoint-down(md) {
			width: 100%;
			text-align: center;
		}
	}
}