.-brands{
	&-track{
		@extend %flex-samesize;
		@extend %flex-dir-row-wrap;		
	}
	&-item{
		flex: 1;
		.iconify{
			font-size: 40px;
			color: $white;
		}
	}
}