.-title{
  &-primary{
    color: inherit;
    font-weight: $bold;
    font-size: 30px;
    @include font-rem(30);
    line-height: 2.25rem;
    letter-spacing: -0.75px;
    padding-bottom: 1.5rem;
    margin-bottom: 2.5rem;
    /*@include media-breakpoint-down(xxl) {
      font-size: 55px;
      @include font-rem(55);
    }
    @include media-breakpoint-down(md) {
      font-size: 32px;
      @include font-rem(32);
    }*/
    @include media-breakpoint-down(md) {
      font-size: 26px;
      @include font-rem(26);
    }
  }

  &-secondary{
    color: inherit;
    font-weight: $bold;
    font-size: 24px;
    @include font-rem(24);
    line-height: 1.1;
    margin-bottom: .5em;
    /*@include media-breakpoint-down(xxl) {
      font-size: 55px;
      @include font-rem(55);
    }*/
    @include media-breakpoint-down(md) {
      font-size: 22px;
      @include font-rem(22);
    }

  }
  &-dotted{
    &:after{
      content: '.';
      color: var(--c-purple-alt);
      font-size: 1.2em;
      margin-left: 3px;
    }
  }
  &-dashed{
    border-bottom: 1px solid lighten($blue-base, 50%);
    &-light{
      border-bottom: 1px solid darken($blue-base, 20%);
    }
  }
}
/*Hrading tags*/
.-ht{
  &-3{
    color: inherit;
    font-weight: $bold;
    font-size: 44px;
    @include font-rem(44);
    line-height: 1.1;
    margin-bottom: .5em;
    @include media-breakpoint-down(md) {
      font-size: 40px;
      @include font-rem(40);
    } 
  }
}

h1, h2, h3, h4, h5, h6{
  margin: 0 0 1.4rem;
}

h1{
  color: inherit;
  font-weight: $bold;
  font-size: 32px;
  @include font-rem(32);
  line-height: 1.34;
}

h2{ 
  color: inherit;
  font-weight: $bold;
  font-size: 32px;
  @include font-rem(32);
  line-height: 1.34;
}



h3{ 
  color: inherit;
  font-weight: $bold;
  font-size: 20px;
  @include font-rem(20);
  line-height: 1.5;
}

h4{ 
  color: inherit;
  font-weight: $bold;
  font-size: 18px;
  @include font-rem(18);
  line-height: 1.5;
  margin-bottom: 0;
}

h5{
}

.-wbold{
  font-weight: $bold;
}