form{
    @include media-breakpoint-up(md) {
        display: flex;
    }

    &:invalid{
        input, textarea, select{ border-color: lighten($gray-base, 40%);}
        button[type="submit"]{
             background-color: lighten($gray-base, 40%);
             border-color: lighten($gray-base, 40%);
             cursor: default;
             opacity: .7;
        }
    }
    a{ 
        color: inherit;
    }
    label{
        color: $green-base;
        font-size: 14px;
        @include font-rem(14);
        font-weight: $bold;

        &+input,
        &+textarea,
        &+select{ 
            margin-top: 6px;
        }
    }

    input{ 
        background: transparent;
        font-size: 14px;
        @include font-rem(14);
        font-weight: $medium;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid;
        border-color: var(--c-text-primary);
        height: 40px;
        box-sizing: border-box;
        outline: none;
        box-shadow: none;
        color: var(--c-text-primary);
        width: 100%;
        padding: 10px 14px;
        margin-bottom: 22px;

        &[type="submit"]{
            @include transition();
            font-size: 20px;
            @include font-rem(20);
            height: 50px;
            padding: 0;
            display: block;
            /*background-color: var(--c-purple-alt);*/
            background-color: $green-lime;
            color: $white;
            border: 1px solid $green-lime;
            /*border: 1px solid $green-base;*/
            font-weight: $bold;
            margin-bottom: 0;
            margin-top: 22px;

            
            &:valid{ 
                color: $white;
            }
        }
        
        &:hover,
        &:focus{
            @include media-breakpoint-up(md) {
                &::placeholder {
                    opacity: .8;
                }
            }
        }

        &::placeholder {
            font-weight: $regular;
            color: var(--c-text-primary);
            opacity: .5;
        }
    } 
    
    textarea{
        background-color: $white;
        border: 1px solid;
        border-color: var(--c-text-primary);
        border-radius: 12px;
        font-size: 16px;
        @include font-rem(16);
        font-weight: $regular;
        color: $gray-base;
        outline: none;
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        padding: 10px 14px;
        /*&::placeholder {
            font-weight: $regular;
            color: $gray-base;
        }*/
        &::placeholder {
            font-weight: $regular;
            color: var(--c-text-primary);
            opacity: .5;
        }
    }

    select{
        border-color: var(--c-text-primary);
        width: 100%;
        outline: none;
        border-radius: 4px;
        padding: 10px 14px;
        margin-bottom: 22px;
        font-size: 14px;
        @include font-rem(14);
    }

    button[type="submit"]{
        @include transition();
        font-size: 16px;
        @include font-rem(16);
        height: 50px;
        padding: 0;
        display: block;
        background-color: $green-lime;
        color: $white;
        border: 1px solid $green-lime;
        font-weight: $bold;
        margin-bottom: 0;
        margin-top: 22px;
        @include border-radius(22px);
        
        &:valid{ 
            color: $white;
        }
    }
    
    .form{
        &-wrap{
            border: 1px solid transparent;
            background-color: var(--c-bg-form);
            color: $blue-base;
            @include border-radius(32px);
            padding: 20px;
            flex: 1;
            @extend %default-box-shadow;
            @include media-breakpoint-down(md) {
                padding: 20px 25px;
            }
        }

        &-group{
            @extend %flex-dir-row-wrap;
            @extend %flex-samesize;
            flex: 1;
            justify-content: center;
            @include media-breakpoint-down(md) {
                -webkit-flex-direction: column;
                flex-direction: column;
            }

            input,
            button{
                flex: 1;
                margin: 0;
                @include media-breakpoint-up(md) {
                    height: auto;
                }
            }
            input{
                @include media-breakpoint-up(md) {
                    max-width: 40%;
                    margin-right: 10px;
                }
            }
            button{ 
                margin-top: 10px;
                padding: 10px;
                @include media-breakpoint-up(md) {
                    max-width: 40%;
                    margin-top: 0;
                    padding: 0;
                }
            }
        }
    }    

}
