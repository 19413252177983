.-header{
  /*-webkit-box-shadow: 0 1px 3px 0 rgba($black, 0.1), 0 1px 1px 1px rgba($black, 0.1);
     -moz-box-shadow: 0 1px 3px 0 rgba($black, 0.1), 0 1px 1px 1px rgba($black, 0.1);
   -khtml-box-shadow: 0 1px 3px 0 rgba($black, 0.1), 0 1px 1px 1px rgba($black, 0.1);
       -o-box-shadow: 0 1px 3px 0 rgba($black, 0.1), 0 1px 1px 1px rgba($black, 0.1);
          box-shadow: 0 1px 3px 0 rgba($black, 0.1), 0 1px 1px 1px rgba($black, 0.1);*/	
	
	&-inner{ 
		@include transition(all .2s linear);
		position: relative;
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;
		padding-bottom: 15px;
		align-items: center;
		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}

	&-brand{
		@include transition(all .2s ease-in);
		color: inherit;
		display: flex;
		align-items: center;
		width: auto;
		padding: 0;
		/*max-width: 207px;*/
		max-width: 300px;
		margin-left: 0;
		margin-right: auto;
		@include media-breakpoint-down(md) {
			max-width: 190px;
		}

		.brand{
			font-size: 24px;
			@include font-rem(24);
			font-weight: $bold;
			position: relative;
			color: $blue-base;
			@extend %flex-dir-col-wrap;
			letter-spacing: 2.3px;
			text-transform: uppercase;
			font-weight: $bold;
			line-height: 1;
			strong{
				color: $green-lime;
				font-weight: $semibold;
				letter-spacing: -1px;
				font-size: 15px;
				@include font-rem(15);
			}
			/*&:before{
				content: '</>';
				color: $green-base;
				font-weight: $semibold;
				margin-right: 7px;
				font-size: 1.4em;
			}*/
		}
		a{
			color: inherit;
			text-decoration: none;
			@include transition(all .2s ease-in);
			position: relative;
			
			&:active,
			&:focus,
			&:hover{
				transform: scale(1.03);
				opacity: .9;
			}
		}
	}
	&-options{
		/*@include media-breakpoint-down(md) {
			margin-top: -100vh;
			@at-root {
				.menu-active &{
					margin-top: 0;
				}
			}
		}*/

		@extend %flex-dir-row-wrap;
		a{
			display: -webkit-flex;
			display: flex;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			 
			@include media-breakpoint-down(xl) {
				margin-left: 5px;
			}
			/*@include media-breakpoint-down(md) {
				margin-top: 15px;
			}*/
			@include media-breakpoint-up(lg) {
				margin-left: 10px;
			}

			&.-btn-cta{
				padding: 8px;
			}
			
		}		
	}
}

.menu-trigger{
	position: absolute;
	width: 24px;
	height: 24px;
	font-size: 24px;
	@include font-rem(24);
	display: flex;
	justify-content: center;
	align-items: center;
	right: 10px;
	@include media-breakpoint-up(md) {
		visibility: hidden;
	}

 	&-mobile{
 		top: 20px;
 		right: 15px;
 	}
	
	
	.-btn{
		@include transition(all .2s ease-in);
		position: relative;
		outline: none;
		display: inline-block;
		border: none;
		text-decoration: none;
		background: transparent; 
		color: inherit;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		line-height: 1;
	}
}

.-light-trigger{
	margin: auto 0;
	/*@include media-breakpoint-down(md) {
		z-index: 3000;
		position: fixed;
		bottom: 50px;
		right: 10px;
	}*/
	&-btn{
		@include transition();
		position: relative;
		@extend %default-box-shadow;
		@extend %flex-wrap;
		justify-content: center;
		background-color: var(--c-bg-purple-btn);
		color: var(--c-purple-btn);
		position: relative;
		outline: none;
		border: none;
		text-decoration: none;
		color: inherit;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		line-height: 1;
		font-size: 24px;
		width: 80px;
		padding: 5px 10px;
		@include border-radius(20px);

		/*@include media-breakpoint-down(md) {
			  -webkit-box-shadow: 3px 5px 10px rgba(0, 0, 0, .4 );
			     -moz-box-shadow: 3px 5px 10px rgba(0, 0, 0, .4 );
			   -khtml-box-shadow: 3px 5px 10px rgba(0, 0, 0, .4 );
			       -o-box-shadow: 3px 5px 10px rgba(0, 0, 0, .4 );
			          box-shadow: 3px 5px 10px rgba(0, 0, 0, .4 );
		}*/
		
		&:hover,
		&:focus,
		&:active{
			text-decoration: none;
			outline: none;
		}

		.iconify{
			@include transition(all .2s);
			color: var(--c-white-primary);
			position: relative;
			&+.iconify{
				color: var(--c-bg-purple-btn);
				//color: #000;

				
				@at-root{
					.dark &{
						color: #000;
					}
				}
			}
		}
	}
}



.brand{
	@extend %image-responsive;
}


/*menu*/
.hamb {
    cursor: pointer;
    display: block;
    height: 24px;
    padding: 16px;
    width: 24px;
    position: relative;
}

.hamb .menu,
.hamb .menu::before,
.hamb .menu::after {
    background: var(--c-text-primary);
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
    width: 20px;
}

.hamb:hover .menu,
.hamb:hover .menu::before,
.hamb:hover .menu::after {
    background: var(--c-text-primary);
}

.hamb .menu {
    left: 7px;
    top: 16px;
}

.hamb .menu::before {
    top: -6px;
}

.hamb .menu::after {
    top: 6px;
}

.menu-active .hamb .menu {
    background: transparent;
}

.menu-active .hamb .menu::before {
    transform: rotate(45deg);
}

.menu-active .hamb .menu::after {
    transform: rotate(-45deg);
}

.menu-active .hamb .menu::before,
.menu-active .hamb .menu::after {
    top: 0;
    transition: top ease .3s, transform ease .3s .3s;
}