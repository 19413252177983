// Colors
$white: 	            	#FFFFFF;
$white-soft:				#FCFCFC;

$gray-base:					#5F5F5F;
$gray-dark:					#181818;
$gray-darker:				#707070;

$blue-base:					#0B2D5B;
$green-lime:				#90C902;


$green-base:				#7149E7;
$purple-base:				#7149E7;

$dark-bg: 					#23283E;
$dark-color: 				#cdcdff;


$green-opacity:			rgba($green-base, .16);
$foreground-opacity:		rgba($dark-bg, .8);


// Typekit Fonts
$poppins-webfont: 'Poppins', sans-serif;

// Fonts
$base-font-family: $poppins-webfont;

// Weights
/*$light:		300;*/
$regular:		400;
$medium:		500;
$semibold:		600;
$bold:			700;
$extrabold:		800;
$wblack:		900;


//Background
$default-bg-color: $white;


/*theme modes*/


:root {
	--c-bg-body-primary: #ffffff;
	--c-text-primary: #5F5F5F;
	--c-text-secondary: #7149E7;
	--c-purple-base: #7149E7;
	--c-purple-alt: #7149E7;
	--c-bg-purple-btn: #7149E7;
	--c-purple-btn: #fff;

	--c-white-primary: #fff;

	--c-bg-lighten-5: #8360ea;
	--c-bg-lighten-12: #9b7fee;
	--c-bg-lighten-20: #7852e8;

	--c-bg-gradient-end: rgba(113,73,231,.25);
	
	--c-bg-form: #fff;

	&.dark {
		--c-bg-body-primary: #23283E;
		--c-text-primary: #e2e8f0;
		--c-text-secondary: #cdcdff;
		--c-purple-base: #141c26;
		--c-purple-alt: #b7a3f3;

		--c-bg-purple-btn: #e2e8f0;
		--c-purple-btn: #23283E;

		--c-white-primary: #e2e8f0;

		--c-bg-lighten-5: #0c0522;
		--c-bg-lighten-12: #141c26;
		--c-bg-lighten-20: #141c26;

		--c-bg-gradient-end: rgba(24,24,24,.45);

		--c-bg-form: #141c26;
	}
}